import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

const Mogotes = () => {

  return (
    <Layout>
      <SEO title="Case Study: Mogotes Concrete" />


    </Layout>
  )
}

export default Mogotes